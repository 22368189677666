import { createBrowserRouter } from "react-router-dom";
// import App from '../App';
import HomeComp from '../pages/HomeComp';
import AboutComp from '../pages/AboutComp';
import ContactComp from '../pages/ContactComp';
import PageNotFound from '../pages/PageNotFoundComp';
import SessionComp from '../pages/SessionComp';

const router = createBrowserRouter([
    {path:"/", element:<HomeComp />},
    {path:"/about", element:<AboutComp />},
     {path:"/session",element:<SessionComp />},
    {path:"/contact", element:<ContactComp />},
    {path:"*",element:<PageNotFound />}

]);

export default router;

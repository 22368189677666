import React from 'react';

const PageNotFoundComp = () => {
    return (
        <div>
            <h2>This is Page Not Found Component</h2>
        </div>
    )
}

export default PageNotFoundComp;

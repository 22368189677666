import React, { useContext } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { WebContext } from '../../App';
import '../../styles/Gym/GymCard.css';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function GymCardComp() {
    const session = useContext(WebContext);
  return (
    <div>
        <Container maxWidth='lg' className='margintb'>
          <Box sx={{ flexGrow: 1 }}>
            <Typography className='batchestitle' variant="h2" gutterBottom>
              Our Sessions
            </Typography>
            <Grid container spacing={2} className='gridwrapper'>
              {
                session?.sessiondata?.map((item)=>{
                  return(
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Item className='itemwrapper'>
                        <Card className='cardwrap'>
                          <CardActionArea>
                            <Link to='/Session'>
                              <CardMedia
                                component="img"
                                className='cardimg'
                                image={item.imgpath}
                                alt={item.name}
                              />
                            <CardContent className='card-img-overlay'>
                              <Typography className='cardtitle' gutterBottom variant="h5" component="div">
                                {item.name}
                              </Typography>
                              {/* <Typography className='cardcontent' variant="body2" color="text.secondary">
                                {item.content}
                              </Typography> */}
                            </CardContent>
                            </Link>
                          </CardActionArea>
                        </Card>
                      </Item>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </Container>
    </div>
  )
}

export default GymCardComp;

import React, {useState, useContext, useEffect } from 'react';
// import Container from 'react-bootstrap/Container';
import Container from '@mui/material/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../styles/components/nav.css';
import { Link } from 'react-router-dom';
import { WebContext } from '../../App.js';
function NavComp() {
  const logoimg = useContext(WebContext);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    let isTop;

    if (window.innerWidth >= 320 && window.innerWidth <= 768) {
      isTop = window.scrollY < 10; 
    } else {
      isTop = window.scrollY < 15;  
    }

    if (!isTop) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(()=>{
    handleScroll();
    window.addEventListener('scroll', handleScroll);
   
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  return (
    <Navbar collapseOnSelect fixed="top" data-bs-theme="dark" expand="lg" className={`navbar ${scrolled?'scrolled':''}`}>
      <Container maxWidth='lg' className='container'>
        <Navbar.Brand>
          <Link to='/'>
            {
              logoimg?.images?.map((item) =>{
                return(
                  <img
                  key={item.id}
                alt="Logo"
                src={item.logopath}
                className="d-inline-block align-top logo "
              />
                )
              })
            }
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='navbarnav' />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto"></Nav> */}
          <Nav className="mx-auto">
            <Link id='link' className='nav-link' to='/'>Home</Link>
            <Link className='nav-link' to='/About'>About</Link>
            <Link className='nav-link' to='/Session'>Session</Link>
            <Link className='nav-link' to='/Contact'>Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavComp;

import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import '../styles/pages/About.css';
import NavComp from '../components/comman/NavComp';
import FooterComp from '../components/comman/FooterComp';
import { WebContext } from '../App';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function AboutComp() {
  const aboutinfo = useContext(WebContext);
  const [isShowMore, setShowMore] = useState(false);
  return (
    <div>
        <NavComp />
        <div className='aboutuswrapper'>
          <Container maxWidth="lg" className='mbt-5'>
            <Box sx={{ flexGrow: 1 }}>
              {
                aboutinfo?.aboutdata?.map((item) =>{
                  return(
                    <Grid container spacing={2} className='imggridwrap'>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Item className='imgwrappr'>
                          <img className='gymimg' src={item.imgpath} alt={item.name} />
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8} lg={8}>
                        <Item className='contentwrapper'>
                          <h2 className='abouttitle'>{item.abouttitle}</h2>
                          <p className='aboutcontent'>
                          {isShowMore ? item.content : item.content.slice(0, 595)}
                          <Button onClick={() => setShowMore(!isShowMore)} className='txtcolor' >{isShowMore ?  "Show less" : "...Show more"}</Button>
                          </p>
                        </Item>
                      </Grid>
                    </Grid>
                  )
                })
              }
            </Box>
          </Container>
        </div>
        <footer>
          <FooterComp />
        </footer>
    </div>
  )
}

export default AboutComp;


import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes/routing';
import { createContext, useEffect, useState } from 'react';

export const WebContext = createContext();

function App() {
  const [JsonData, setJsonData] = useState([]);
  const getData = async()=>{
    try{
       const res = await fetch(`/db.json`);
       const jsonRes = await res.json();
       console.log(jsonRes);
       setJsonData(jsonRes);   
    }
    catch(error){console.log('Error:', error)};
  }

  useEffect(()=>{
    getData();
  },[]);
  return (
    <WebContext.Provider value={JsonData}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </WebContext.Provider>
  );
}

export default App;
import React, { useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import '../../styles/components/header.css';
import { WebContext } from '../../App';

function HeaderComp() {
 const slideimg = useContext(WebContext);
  return (
    <div>
        <Carousel>
        {
          slideimg?.bannerimgs?.map((item)=>{
            return(
              <Carousel.Item interval={item.interval} className='itemcarousel'>
                <Image src={item.imgpath} fluid />
              </Carousel.Item>
            )
          })
        }
    </Carousel>
    </div>
  )
}

export default HeaderComp;

import React from 'react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '../../styles/Gym/Achievement.css';
import { Container } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function Achievenent() {
  return (
    <div>
         <Container maxWidth='lg' className='marginTB'>
            <Typography className='achievementtitle' variant="h2" gutterBottom>Our Achievements</Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Item>
                           <div className='counterwrapper'>
                                <Typography className='countwrap' variant="h2" gutterBottom>
                                    <div className='counter'>
                                        <h2 className='counterno'>020</h2>
                                    </div>
                                </Typography>
                                <Typography className='iconwrapper' variant="body1" gutterBottom>
                                    <BusinessCenterIcon className='suitcaseicon' />
                                </Typography>
                                <Typography className='countertitle' variant="h4" gutterBottom>
                                    Training Courses
                                </Typography>
                           </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Item>
                            <div className='counterwrapper'>
                                <Typography className='countwrap' variant="h2" gutterBottom>
                                    <div className='counter'>
                                        <h2 className='counterno'>900</h2>
                                    </div>
                                </Typography>
                                <Typography className='iconwrapper' variant="body1" gutterBottom>
                                    <WatchLaterIcon className='suitcaseicon' />
                                </Typography>
                                <Typography className='countertitle' variant="h4" gutterBottom>
                                    Working Hours
                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Item>
                            <div className='counterwrapper'>
                                <Typography className='countwrap' variant="h2" gutterBottom>
                                    <div className='counter'>
                                        <h2 className='counterno'>200</h2>
                                    </div>
                                </Typography>
                                <Typography className='iconwrapper' variant="body1" gutterBottom>
                                    <PeopleAltIcon className='suitcaseicon' />
                                </Typography>
                                <Typography className='countertitle' variant="h4" gutterBottom>
                                    Happy Customer
                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Item>
                        <div className='counterwrapper'>
                                <Typography className='countwrap' variant="h2" gutterBottom>
                                    <div className='counter'>
                                        <h2 className='counterno'>5</h2>
                                    </div>
                                </Typography>
                                <Typography className='iconwrapper' variant="body1" gutterBottom>
                                    <EmojiEventsIcon className='suitcaseicon' />
                                </Typography>
                                <Typography className='countertitle' variant="h4" gutterBottom>
                                    Awards
                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
         </Container>
    </div>
  )
}

export default Achievenent;

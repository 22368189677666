import React from 'react';
import NavComp from '../components/comman/NavComp';
import HeaderComp from '../components/comman/HeaderComp';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FooterComp from '../components/comman/FooterComp';
import '../styles/main.css';
import GymCardComp from '../components/gym/GymCardComp';
import Achievenent from '../components/gym/Achievenent';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function HomeComp() {
  return (
    <div>
      <NavComp />
      <header>
        <HeaderComp />
      </header>
      <section className='aboutwrapper'>
        <Container maxWidth='lg'>
          <Box className='boxwrapper'>
            <Typography className='abttitle' variant="h2" gutterBottom>
              About US
            </Typography>
            <Typography className='abtcontent' variant="body1" gutterBottom>
            Welcome to GymLyfe CLUB. Where fitness meets community! Our GymLyfe is more than just a place to work out – it's a supportive
            environment where members of all fitness levels come together to achieve their goals, challenge themselves, and have fun along the way.
            Our state-of-the-art facilities offer everything you need for a
            comprehensive workout experience. From cutting-edge equipment and spacious workout areas to a variety of group fitness classes led by
            certified instructors, there's something for everyone at our GymLyfe
            club.
            But we're more than just a gym – we're a community. Join us for social
            events, fitness challenges, and member-exclusive perks that make working out feel like a celebration. Connect with like-minded individuals who share your passion for health and wellness, and discover the joy of reaching your fitness goals together.
            Whether you're looking to lose weight, build strength, improve your flexibility, or simply stay active and healthy, GymLyfe Club is here to help you succeed. Come be a part of our vibrant community and experience the difference at GymLyfe CLUB today!
            </Typography>
            <div>
              <Fab className='btnmore' variant="extended">
                <Link className='btnlink' to='/About'>More</Link>
              </Fab>
            </div>
          </Box>
        </Container>
      </section>
      <section className='margintb'>
        <Container maxWidth='lg'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Item className='aboutsection'>
                  <Typography className='iconwrap' variant="h2" gutterBottom>
                    <div className='iconbox'>
                      <GroupsIcon className='groupicon' />
                    </div>
                  </Typography>
                  <Typography className='boxtitle' variant="h3" gutterBottom>Winner Coaches</Typography>
                  <Typography className='boxcontent' variant="body1" gutterBottom>
                  At Gym Life, we pride ourselves on having a team of coaches who are not only dedicated and experienced but also have proven their excellence in the field by winning prestigious awards.
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Item className='aboutsection'>
                <Typography className='iconwrap' variant="h2" gutterBottom>
                    <div className='iconbox'>
                      <LocalOfferIcon className='groupicon' />
                    </div>
                  </Typography>
                  <Typography className='boxtitle' variant="h3" gutterBottom>Affordable Price</Typography>
                  <Typography className='boxcontent' variant="body1" gutterBottom>
                  At Gym Life, we believe that everyone should have access to high-quality fitness facilities and expert coaching, without breaking the bank. That's why we've designed our pricing plans to be flexible, affordable, and tailored to meet your needs.
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Item className='aboutsection'>
                    <Typography className='iconwrap' variant="h2" gutterBottom>
                      <div className='iconbox'>
                        <FitnessCenterIcon className='groupicon' />
                      </div>
                    </Typography>
                    <Typography className='boxtitle' variant="h3" gutterBottom>Modern Equipments</Typography>
                    <Typography className='boxcontent' variant="body1" gutterBottom>
                    At Gym Life, we are committed to providing our members with the best fitness experience possible. Our facility is equipped with the latest technology and high-quality machines, ensuring a safe, enjoyable, and productive workout every time you visit.
                    </Typography>
                 </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
      <section className='batcheswrapper'>
        <GymCardComp />
      </section>
      <section className='achievementwrapper'>
        <Achievenent />
      </section>
      <footer>
        <FooterComp />
      </footer>
    </div>
  )
}
export default HomeComp;

import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import '../styles/pages/SessionComp.css';
import NavComp from '../components/comman/NavComp';
import FooterComp from '../components/comman/FooterComp';
import { WebContext } from '../App';

const CustomItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: '#212529',
  boxShadow: 'none',
}));

function SessionComp() {
  const sessioninfo = useContext(WebContext);
  const [isShowMore, setShowMore] = useState(false);

  return (
    <div>
      <NavComp />
      <div className='sessionwrapper'>
        <Container maxWidth="lg" className='mbt-5'>
          <Box sx={{ flexGrow: 1 }}>
            {sessioninfo?.sessionimg?.map((item, index) => (
              // <Grid container spacing={2} key={index} className={index % 2 === 0 ? 'customGrid' : 'customGrid customGridReverse'}>
              <Grid container spacing={2} key={index} className='customGrid'>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <CustomItem className='imgwrapper'>
                    <img className='gymimg' src={item.imgpath} alt={item.name} />
                  </CustomItem>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={8}>
                  <CustomItem className='contentwrapper'>
                    <h2 className='sessiontitle'>{item.name}</h2>
                    <p className='sessioncontent'>
                      {isShowMore ? item.content : `${item.content.slice(0, 595)}...`}
                      <Button onClick={() => setShowMore(!isShowMore)} className='txtcolor'>
                        {isShowMore ? "Show less" : "Show more"}
                      </Button>
                    </p>
                  </CustomItem>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Container>
      </div>
      <footer>
        <FooterComp />
      </footer>
    </div>
  );
}

export default SessionComp;
import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import '../../styles/components/footer.css';
import { Container, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { WebContext } from '../../App';

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));

  
function FooterComp() {
    const gallery = useContext(WebContext);
  return (
    <footer className='footerwrapper'>
        <Container maxWidth='lg'>
        <Box className="footerbox">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Item className='itemwrap'>
            {
                gallery?.images?.map((item)=>{
                    return(
                        <Link to='/'>
                            <img className='footerlogo' key={item.id} alt="Logo" src={item.logopath} />
                        </Link>
                    )
                })
            }
            <div className='addresswrapper'>
                <List>
                    <ListItem className='listwrapper'>
                        <ListItemIcon className='itemicon'>
                            <PlaceIcon className='placeicon' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography className='footer-address'>
                                Kapil Nagar St, Ramwadi, Wadgaon Sheri, Pune, Maharashtra 411014. 
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem className='listwrapper'>
                        <ListItemIcon className='itemicon'>
                            <PhoneIcon className='placeicon' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography className='footer-address'>
                                <a className='address' href='tel:8929100100'>+91 8929100100</a>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem className='listwrapper'>
                        <ListItemIcon className='itemicon'>
                            <EmailIcon className='placeicon' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography className='footer-address'>
                                <a className='address' href='mailto:kapilagarwal2284@gmail.com'>kapilagarwal2284@gmail.com</a>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem className='listwrapper'>
                        <ListItemIcon className='itemicon'>
                            <LanguageIcon className='placeicon' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography className='footer-address'>
                                <a className='address' href='https://gymlifeclub.org/'>www.gymlifeclub.org</a>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item className='linkwrapper itemwrap'>
             <h4 className='footerheading'>Useful Links</h4>
             <List>
                <ListItem className='listwrapper'>
                    <ListItemText>
                        <Link className='linklist'><NavLink className="link" to='/'>Home</NavLink></Link>
                    </ListItemText>
                    <ListItemIcon className='itemicon'>
                            <ChevronRightIcon className='RightIcon' />
                    </ListItemIcon>
                </ListItem>
                <ListItem className='listwrapper'>
                    <ListItemText>
                        <Link className='linklist'><NavLink className="link" to='/About'>AboutUS</NavLink></Link>
                    </ListItemText>
                    <ListItemIcon className='itemicon'>
                            <ChevronRightIcon className='RightIcon' />
                    </ListItemIcon>
                </ListItem>
                <ListItem className='listwrapper'>
                    <ListItemText>
                        <Link className='linklist'><NavLink className="link" to='/Session'>Session</NavLink></Link>
                    </ListItemText>
                    <ListItemIcon className='itemicon'>
                            <ChevronRightIcon className='RightIcon' />
                    </ListItemIcon>
                </ListItem>
                <ListItem className='listwrapper'>
                    <ListItemText>
                        <Link className='linklist'><NavLink className="link" to='/Contact'>ContactUS</NavLink></Link>
                    </ListItemText>
                    <ListItemIcon className='itemicon'>
                            <ChevronRightIcon className='RightIcon' />
                    </ListItemIcon>
                </ListItem>
             </List>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item className='itemwrap'>
            <h4 className='footerheading'>Gallery</h4>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 1, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                    {gallery?.galleryimgs?.map((item, index) => (
                    <Grid item xs={4} sm={4} md={4} key={index}>
                        <Item className='itemwrap'>
                            <img className='galleryimg' src={item.imgpath} alt={item.name} />
                        </Item>
                    </Grid>
                    ))}
                </Grid>
            </Box>
          </Item>
        </Grid>
        {/* <Grid item xs={12} md={3} lg={3}>
          <Item>
            <h4 className='footerheading'>Newsletter</h4>
            <p className='footercontent'>By subscribing us you will get discount offers and our tips for your fitness for good results.</p>
          </Item>
        </Grid> */}
      </Grid>
    </Box>
    <Box>
        <Typography className='copyrights'>&copy; All Rights Reserved | Designed and Developed by
                GymLife Club.</Typography>
    </Box>
    </Container>
    </footer>
  )
}

export default FooterComp;


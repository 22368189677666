import React from 'react';
import { Grid, Typography, Link, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import Container from '@mui/material/Container';
import '../styles/pages/ContactComp.css';
import NavComp from '../components/comman/NavComp';
import FooterComp from '../components/comman/FooterComp';

const ContactComp = () => {
  return (
    <div className='contact-wrapper'>
       <header>
        <NavComp />
        </header>

    <div className="contact-container">
        <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <Box className="map-section">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.3785692698707!2d73.90902517380171!3d18.556963868064315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1154fe9ed07%3A0x95c1bbb3de9cf1f4!2sGymlife%20(Gym%20%26Cricket%20turf)!5e0!3m2!1sen!2sin!4v1720270099961!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Gym-life"
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="contact-section">
              <div className="contact-item">
                <PhoneIcon className="contact-icon" />
                <Typography variant="body1"><Link className='contact-address' href='tel:8929100100'>+91 8929100100</Link></Typography>
              </div>
              <div className="contact-item">
                <EmailIcon className="contact-icon" />
                <Typography variant="body1"><Link className='contact-address' href='mailto:kapilagarwal2284@gmail.com'>kapilagarwal2284@gmail.com</Link></Typography>
              </div>
              <div className="contact-item">
                <LanguageIcon className="contact-icon" />
                <Typography variant="body1" >
                  <Link className='contact-address' href="http://www.gymlifeclub.org">www.gymlifeclub.org</Link>
                </Typography>
              </div>
              <div className="contact-item">
                <LocationOnIcon className="contact-icon" />
                <Typography variant="body1" className="address-text">
                  <p className='contact-address'>
                  Kapil Nagar St, Ramwadi, Wadgaon Sheri, Pune, Maharashtra-411014.
                  </p>
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        </Container>
        </div>
        <footer>
          <FooterComp />
        </footer>
    </div>
  );
};

export default ContactComp;
